import React from "react"
import Layout from "../components/Layout"
import Home from "../components/Home"
import { graphql } from "gatsby"
import SEO from "../components/Seo"

const Homepage = props => {
  return (
    <Layout>
      <SEO
        title="Pilates Classes Online, Chronic Pain Coaching"
        description="Online Pilates classes and Chronic Pain / Symptoms Coaching.
          Pilates is suitable for many purposes – fitness, better posture,
          athlete's performance improvement, injury rehabilitation, and most
          important to help us feel great.
          I believe that Pilates is great for everybody at every age, it helps
          to improve our posture and our fitness level. 
          Pilates guides us on how to think and move differently, be more
          focused, and be more coordinated with our body and mind.
          Thai yoga massage is an ancient Asian technique to relieve tension and
          enhance flexibility and range of motion. 
          Fascial release massage treating skeletal muscle immobility and pain
          by relaxing contracted muscles, improving blood and lymphatic
          circulation, and stimulating the stretch reflex in muscles.
          Classical Pilates, Contemporary Pilates,  Mat and Apparatus , Thai massage , Myofascial
          Release"
      />
      <Home data={props.data} />
    </Layout>
  )
}

export default Homepage

export const query = graphql`
  query {
    monkeyHang: file(relativePath: { eq: "oryan-monkey-hang.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    reformerLegsLift: file(relativePath: { eq: "reformer-legs-lift.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oryanTeaser: file(relativePath: { eq: "oryan-back-lay.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cadillacLegsHang: file(relativePath: { eq: "cadillac-legs-hang.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    backTeaserCadillac: file(relativePath: { eq: "back-teaser-cadillac.jpg" }) {
      name
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
